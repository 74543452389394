import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Content switcher`}</em>{` manipulates the content shown following an exclusive or “either/or” pattern.
It is used to toggle between two or more content sections within the same space on screen. Only one section can be shown at a time.`}</p>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p>{`Be concise and specific. Titles have a max of two words.`}</p>
    <h4 {...{
      "id": "default-selection"
    }}>{`Default selection`}</h4>
    <p>{`Based on usage, there should be a default selection. The default selection is always the first option in a switcher.`}</p>
    <h2 {...{
      "id": "related-components"
    }}>{`Related components`}</h2>
    <h4 {...{
      "id": "content-switcher-vs-toggle"
    }}>{`Content switcher vs. toggle`}</h4>
    <p>{`The content switcher is used for large groups of content, as opposed to the `}<a parentName="p" {...{
        "href": "/components/toggle"
      }}>{`toggle`}</a>{` which is meant for a “yes/no” or “on/off” binary decision.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.69021739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAACjUlEQVQ4y62SP0wiQRTGR8VbBJHlP+yyCwiYXHVEsKOxwlgZS2JhYjQmxEQqaEgsKbWhoaGhAjsSEmOFvQWJCqhgQKNRY4KGS7TxvhmRc+/Ou+Ym+eWbN8z79s3jEaPRSOiampoiHo+HuN1uxuzsLHl9fSX7+/vk6OiI3Wk2m+RPSxAE5YHZbCb/Wjc3N0zv7+/9Ly8vwVgs9s1gMAQlSXIoTK1WK1NRFCccDocIBKo+n2+Unkej0aGdnR2yt7c3TOPb29vS8/Nzb2Njo+N0Or/jNSl67nK5RhQVJJNJfmlpSYrH45Z8Pq/rdrtjeLL6FziYmR4fHx3ZbNa2trYmrq+vT9D8lZWVNyOTycTU7/dr8RU7vmhGP8e9Xi+PMx0q1fXjCcR6XNX8tTd2u/3deNxisTj6CEAEUj+2AScFvZIikcjIp4Y2m+1d9cAH3GASuD7sqXqBDEwoQiPLMssLh8NM0+m00hCXePAV+MAk8AL/hz2L8YfxqFIdCoXYWG1vb7P8s7OzN0NcYMpjYSZdQAIiWuCGyn2cQKB7vV5v0GJ9fOXd3d3PQKVSMZ2bmxubmZkxB4NBioVqIBCwTU9PWzY3N7+gEhUFVQ3Tylqt1vD5+flIp9MZovmXl5fKXiYSCd3i4qKIXuiQMPr09MRhPLher8ddXV1xGGzu+vqaq9fr6sPDQzU9w2xqdnd32bzmcjml4erqKj8/Py8vLCwIqGT84eGBq1ar6uPjY65Wqw1oNBoMal4qlTSYWWaYyWSUhqlUyrC8vCxvbW2Z0RMNkrWnp6cD0PSBwlDbbrc1lUpFVy6XOZpfKBTIf1sw/v2wWCySg4MDcnFxQVABGwNU9Cn0d/ST4CUs/+TkhOkPEAsmtOB7vMIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "content switcher example",
            "title": "content switcher example",
            "src": "/static/988ed9f3e325920634f28eb6ddcf3310/fb070/content-switcher-usage-1.png",
            "srcSet": ["/static/988ed9f3e325920634f28eb6ddcf3310/d6747/content-switcher-usage-1.png 288w", "/static/988ed9f3e325920634f28eb6ddcf3310/09548/content-switcher-usage-1.png 576w", "/static/988ed9f3e325920634f28eb6ddcf3310/fb070/content-switcher-usage-1.png 1152w", "/static/988ed9f3e325920634f28eb6ddcf3310/c3e47/content-switcher-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      